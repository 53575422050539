/* -----------------------------------------------------------------------------
 *
 * Organisms: Site Error
 *
 * ----------------------------------------------------------------------------- */

.section {
  padding: 60px 24px;
  border-radius: 6px;
  background-color: var( --color-mine-shaft-1 );
  text-align: center;
}
